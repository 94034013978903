import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseFacade } from '@libs/auth/firebase/firebase.facade';
import { SharedFacade } from '@libs/common/store/facades/shared.facade';
import { SNACKBAR_UUID_TOKEN } from '@libs/common/store/reducers/shared.reducer';
import { environment } from '@staff-platform/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export interface IErrorDetails {
  message: string;
  title: string;
  statusCode: number;
  error?: string;
}
@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly sharedFacade: SharedFacade,
    private readonly firebaseFacade: FirebaseFacade,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.firebaseFacade.userToken$.pipe(
      switchMap((token) => {
        const options = {
          url: req.url,
          setHeaders: {
            'x-api-key': environment.apiKey,
            ...(token && { authorization: `Bearer ${token}` }),
          },
          ...(!req.url.includes('i18n') && { url: `${environment.apiUrl}${req.url}` }),
        };

        const request = req.clone(options);

        const disableToastr = [
          `${environment.apiUrl}/v1/auth/login`,
          `${environment.apiUrl}/v1/auth/signup/students`,
          `${environment.apiUrl}/v1/auth/verify/email`,
        ];

        return next.handle(request).pipe(
          catchError((err: unknown) => {
            if (!disableToastr.includes(request.url)) {
              if (err instanceof HttpErrorResponse) {
                this.sharedFacade.setGlobalLoader(false);

                let errorDetails: IErrorDetails = err.error;
                if (!errorDetails.statusCode) {
                  errorDetails = JSON.parse(err.error);
                  errorDetails.title = errorDetails.error;
                }

                const snackbarUuid = request.context.get(SNACKBAR_UUID_TOKEN);

                if (errorDetails.statusCode !== 404) {
                  try {
                    this.sharedFacade.showSnackBar({
                      type: 'error',
                      message: errorDetails.message,
                      options: {
                        closeButton: false,
                        positionClass: 'toast-bottom-center',
                        timeOut: 5000,
                        disableTimeOut: false,
                      },
                      uuid: snackbarUuid,
                    });
                  } catch (e) {
                    this.sharedFacade.showSnackBar({
                      type: 'error',
                      message: 'An error occurred',
                      options: {
                        closeButton: false,
                        positionClass: 'toast-bottom-center',
                        timeOut: 5000,
                        disableTimeOut: false,
                      },
                    });
                  }
                }
              }
            }

            return throwError(() => err);
          }),
        );
      }),
    );
  }
}

import { IStaff, Staff } from '../../staff/models/staff.model';
import { IStudentStats } from '../../student/models/student-stats.model';
import { Student } from '../../student/models/student.model';
import { IUnitType, UnitType } from '../../unit-types/models/unit-type.model';
import { Role } from '../../user/enums/role.enum';
import { ExercisesCorrectionMode } from '../enums/exercise-correction-mode.enum';
import { UnitStatus } from '../enums/unit-status.enum';
import { ILesson, Lesson } from './lesson.model';

export interface IUnitVersion {
  version: number;
  id: string;
}

export interface IRemoveStudentsResponse {
  success: boolean;
  error: string;
  message: string;
}

export interface IUnit {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  displayName: string;
  status: UnitStatus;
  type: IUnitType;
  owner: IStaff;
  supervisor: Role;
  duration: number;
  language: string;
  lessons: ILesson[];
  version: number;
  isLastVersion: boolean;
  exercisesCorrectionMode: string;
  parent?: string;
  index?: number;
  unlocked?: boolean;
  technology?: string;
}

export interface IUnitStudent extends Omit<IUnit, 'id' | 'supervisor'> {
  unitId: string;
  stats: IStudentStats;
  student: Student;
  supervisor: Staff;
  exerciseTutor: Staff | null;
  groupTutor: Staff | null;
  liveTutor: Staff | null;
  liveExerciseTutor: Staff | null;
  assignMethod: string;
}

export class Unit {
  constructor(
    public id: string,
    public createdAt: Date,
    public updatedAt: Date,
    public name: string,
    public displayName: string,
    public status: UnitStatus,
    public type: UnitType,
    public owner: Staff,
    public supervisor: Role,
    public duration: number,
    public language: string,
    public lessons: Lesson[],
    public version = 1,
    public isLastVersion: boolean,
    public exercisesCorrectionMode: ExercisesCorrectionMode,
    public parent?: string,
    public index?: number,
    public unlocked?: boolean,
    public technology?: string,
  ) {}

  public static Build(unit: IUnit): Unit {
    const lessons = unit.lessons?.map((lesson) => Lesson.Build(lesson))?.sort((a, b) => a.index - b.index) || [];

    return new Unit(
      unit.id,
      new Date(unit.createdAt),
      new Date(unit.updatedAt),
      unit.name,
      unit.displayName,
      unit.status,
      unit.type && UnitType.Build(unit.type),
      Staff.Build(unit.owner),
      unit.supervisor,
      unit.duration,
      unit.language,
      lessons,
      unit.version,
      unit.isLastVersion,
      unit.exercisesCorrectionMode as ExercisesCorrectionMode,
      unit.parent,
      unit.index,
      unit.unlocked,
      unit.technology,
    );
  }

  public showEditModeDialog(): boolean {
    return this.status === UnitStatus.PUBLISHED;
  }

  public isValid(): boolean {
    return this.lessons.length > 0 && this.lessons.every((lesson) => lesson.contents.length);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUnit } from '@libs/data/features/unit/models/unit.model';
import { IClassUnitStudent } from '@staff-platform/app/shared/models/class-unit-student.model';
import { Observable } from 'rxjs';
import { PaginatedModel } from '../../../../models/pagination.model';
import { StudentUnitContentAnswer } from '../../models/content/student-unit-content-answer.model';
import { IStudentUnitContent } from '../../models/content/student-unit-content.model';
import { ISessionTimeTrackingModel } from '../../models/session-time-tracking.model';
import { IStudentHomepageUnit } from '../../models/student-homepage-unit.model';
import { IStudentUnitType } from '../../models/student-unit-type.model';
import { IStudentUnit } from '../../models/student-unit.model';
import { IStudentUnitsPayload } from '../../models/sudents-units-payload.model';
import { IVideoTrackingPayload } from '../../models/video-tracking.model';
import { IStudentDashboardUnitType } from '../../models/student-dashboard-unit-type.model';
import { IClass } from '@libs/data/features/class/models/class.model';

@Injectable({
  providedIn: 'root',
})
export class V1StudentRepository {
  constructor(private readonly http: HttpClient) {}

  public getStudentUnits(studentId: string, page = 1, pagelen?: number): Observable<PaginatedModel<IClassUnitStudent>> {
    let params = new HttpParams().set('page', page);

    if (pagelen) {
      params = params.set('pagelen', pagelen);
    }

    return this.http.get<PaginatedModel<IClassUnitStudent>>(`/v1/students/${studentId}/units`, { params });
  }

  public removeUnit(studentId: string, params: IStudentUnitsPayload): Observable<void> {
    return this.http.patch<void>(`/v1/students/${studentId}/units`, params);
  }

  public addUnits(studentId: string, params: IStudentUnitsPayload): Observable<void> {
    return this.http.put<void>(`/v1/students/${studentId}/units`, params);
  }

  public getUnlockableUnits(studentId: string, search: string): Observable<PaginatedModel<IUnit>> {
    const params = new HttpParams().set('search', search);
    return this.http.get<PaginatedModel<IUnit>>(`/v1/students/${studentId}/unlockable-units`, { params });
  }

  public getStudentUnitTypes(page = 1, pagelen?: number): Observable<PaginatedModel<IStudentUnitType>> {
    let params = new HttpParams().set('page', page);

    if (pagelen) {
      params = params.set('pagelen', pagelen);
    }

    return this.http.get<PaginatedModel<IStudentUnitType>>(`/v1/me-student/unit-types`, { params });
  }

  public getUnitsOfStudent(
    unitTypeId: string,
    page = 1,
    pagelen?: number,
  ): Observable<PaginatedModel<IStudentHomepageUnit>> {
    let params = new HttpParams().set('page', page);

    if (pagelen) {
      params = params.set('pagelen', pagelen);
    }
    return this.http.get<PaginatedModel<IStudentHomepageUnit>>(`/v1/me-student/unit-types/${unitTypeId}/units`, {
      params,
    });
  }

  public getStudentUnitById(unitId: string): Observable<IStudentUnit> {
    return this.http.get<IStudentUnit>(`/v1/me-student/units/${unitId}`);
  }

  public getStudentDashboard(): Observable<{ classes: IClass[]; unitTypes: IStudentDashboardUnitType[] }> {
    return this.http.get<{ classes: IClass[]; unitTypes: IStudentDashboardUnitType[] }>(`/v1/me-student/dashboard`);
  }

  public saveStudentUnitLessonContentAnswer(
    unitId: string,
    lessonId: string,
    contentId: string,
    answerPayload: StudentUnitContentAnswer,
  ): Observable<IStudentUnitContent> {
    return this.http.post<IStudentUnitContent>(
      `/v1/me-student/units/${unitId}/lessons/${lessonId}/contents/${contentId}`,
      answerPayload,
    );
  }

  public updateSession(sessionInfo: ISessionTimeTrackingModel): Observable<ISessionTimeTrackingModel> {
    return this.http.post<ISessionTimeTrackingModel>('/v1/me-student/sessions', sessionInfo);
  }

  public updateVideoTracking(videoTrackingPayload: IVideoTrackingPayload): Observable<IVideoTrackingPayload> {
    return this.http.post<IVideoTrackingPayload>('/v1/me-student/video-events', videoTrackingPayload);
  }

  public getImpersonationToken(userId: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`/v1/students/${userId}/impersonate`, {});
  }
}

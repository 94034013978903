import { Nullable } from '@libs/common/types/nullable.type';
import { Permission } from '../../permissions/enums/permission.enum';
import { IStudentPersonalData } from '../../student/models/student.model';
import { Role } from '../enums/role.enum';
import { IUtm } from './utm.model';

export interface IUserSignupPayload {
  email?: string;
  language: string;
  tcAcceptedVersion: string;
  newsletterAccepted: boolean;
  timezone: string;
  personalData?: Pick<IStudentPersonalData, 'profilePhotoUrl'>;
  utm?: IUtm;
}

export interface IUser {
  id: string;
  createdAt: string;
  updatedAt: string;
  bannedAt: string;
  verifiedAt: string;
  lastAccessAt: string;
  permissions: Nullable<Permission[]>;
  role: Role;
  tcAcceptedAt: string;
  tcAcceptedVersion: string;
  newsletterAccepted?: boolean;
  firstname: string;
  lastname: string;
  country: string;
  language: string;
  email: string;
  verified: boolean;
  timezone: string;
  utm: Nullable<IUtm>;
  fullName?: string;
  githubUrl?: string; // TODO: questa proprietà viene usata solo per intercom in app.component
}

export abstract class User implements IUser {
  protected constructor(
    public id: string,
    public createdAt: string,
    public updatedAt: string,
    public bannedAt: string,
    public verifiedAt: string,
    public lastAccessAt: string,
    public permissions: Nullable<Permission[]>,
    public role: Role,
    public tcAcceptedAt: string,
    public tcAcceptedVersion: string,
    public newsletterAccepted: boolean,
    public firstname: string,
    public lastname: string,
    public country: string,
    public language: string,
    public email: string,
    public verified: boolean,
    public timezone: string,
    public utm: Nullable<IUtm>,
    public githubUrl: string,
  ) {}

  public get fullName(): string {
    return this.firstname && this.lastname ? `${this.firstname} ${this.lastname}` : '';
  }

  public get currentUserInitials(): string {
    return `${this.firstname.charAt(0)}${this.lastname.charAt(0)}`;
  }

  public hasPermission(userPermission: Permission): boolean {
    if (this.permissions) {
      if (userPermission.role) {
        return userPermission.role === this.role;
      }

      const feature = this.permissions.find(({ feature }) => feature === userPermission.feature);

      if (feature) {
        return feature.actions.some((action) => userPermission.actions.includes(action));
      }
    }

    return false;
  }
}

import { Directive, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from '@libs/data/features/permissions/enums/permission.enum';
import { User } from '@libs/data/features/user/models/user.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthFacade } from '../store/facades/auth.facade';

@Directive({
  selector: '[authPermission]',
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() authPermissionAction: 'class' | 'hide' | 'attribute' = 'hide';
  @Input() authPermissionClassNames = ['disabled'];
  @Input() authPermissionAttribute: { key: string; value: string } = { key: 'disabled', value: 'true' };

  @Input() set authPermission(action: Permission | undefined) {
    this.action = action;

    this.checkPermission();
  }

  private user!: User;
  private action?: Permission | undefined;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2,
    private readonly authFacade: AuthFacade,
  ) {}

  public ngOnInit(): void {
    this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      this.checkPermission();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkPermission(): void {
    if (!this.action || this.user?.hasPermission(this.action)) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.executeActionOnComponent();
    }
  }

  private executeActionOnComponent(): void {
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);

    switch (this.authPermissionAction) {
      case 'class':
        this.authPermissionClassNames.forEach((c) => this.renderer.addClass(view.rootNodes[0], c));
        break;
      case 'attribute':
        this.renderer.setAttribute(
          view.rootNodes[0],
          this.authPermissionAttribute.key,
          this.authPermissionAttribute.value,
        );
        break;
      case 'hide':
      default:
        this.viewContainerRef.clear();
        break;
    }
  }
}
